<template>
  <div
    class="container"
    v-title
    id="servicePlatformData"
    :data-title="$t('i18n.servicePlatformData')"
  >
    <div id="outer-title">{{ $t("i18n.servicePlatformData") }}</div>
    <jl-form
      :columns="searchColumns"
      inline="true"
      :option="btnOption"
    ></jl-form>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in formArr"
        :key="item"
        :label="$t('i18n.' + item.label)"
        :name="item.name"
      >
        <p>{{ $t("i18n." + item.title) }}</p>
        <div class="form">
          <div v-for="child in item.data" :key="child" class="item">
            <label>{{ $t("i18n." + child.label) }}</label>
            <span>{{ child.value }}</span>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlForm from "../../components/form";
export default {
  name: "SqDataReport",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      searchColumns: [
        {
          prop: "date",
          label: "date",
          type: "date",
        },
        {
          prop: "date",
          label: "maintenancePerson",
          data: [],
          type: "select",
          props: { label: "name", value: "id" },
        },
      ],
      btnOption: [
        {
          label: "query",
          class: "primary",
          show: true,
        },
        {
          label: "export",
          class: "export",
          show: true,
        },
      ],
      activeName: "1", // 选项卡选中
      formArr: [
        {
          label: "servicePlatformUnitsList",
          title: "servicePlatformUnitsList",
          name: "1",
          data: [
            { label: "selfMaintenanceUnitsPaid", value: "0" },
            { label: "selfMaintenanceUnitsFree", value: "0" },
            { label: "commissionMaintenanceUnitsFree", value: "0" },
            { label: "none", value: "none" },
            { label: "newDue", value: "0" },
            { label: "dueFreeMaintenance", value: "0" },
            { label: "freeToPaidMaintenance", value: "0" },
            { label: "lostDueFreeMaintenance", value: "0" },
            { label: "duePaidMaintenance", value: "0" },
            { label: "renewPaidMaintenance", value: "0" },
            { label: "lostPaidMaintenance", value: "0" },
            { label: "newPaidMaintenance", value: "0" },
          ],
        },
        {
          label: "serviceCenterIOT",
          title: "serviceCenterIOT",
          name: "2",
          data: [
            { label: "totalAmountMO", value: "0" },
            { label: "completeMO", value: "0" },
            { label: "unfinishedMO", value: "0" },
            { label: "completeRateMO", value: "none" },
            { label: "totalAmountRO", value: "0" },
            { label: "completeRO", value: "0" },
            { label: "unfinishedRO", value: "0" },
            { label: "completeRateRO", value: "0" },
            { label: "totalAmountTO", value: "0" },
            { label: "completeTO", value: "0" },
            { label: "unfinishedTO", value: "0" },
            { label: "completeRateTO", value: "0" },
            { label: "totalAmountCFO", value: "0" },
            { label: "completeCFO", value: "0" },
            { label: "unfinishedCFO", value: "0" },
            { label: "completeRateCFO", value: "0" },
            { label: "totalHoursMO", value: "0" },
            { label: "totalHoursRO", value: "0" },
            { label: "totalHoursTO", value: "0" },
            { label: "totalHoursMRO", value: "0" },
            { label: "aResponseRO", value: "0" },
            { label: "aRepairRO", value: "0" },
            { label: "aResponseTO", value: "0" },
            { label: "aRescueTO", value: "0" },
            { label: "totalTrappedTimes", value: "0" },
            { label: "totalTrappedRate", value: "0" },
            { label: "totalVehicleMilesTravelM", value: "0" },
            { label: "totalVehicleMilesTravelT", value: "0" },
            { label: "totalUnitsSMC", value: "0" },
            { label: "totalQualifiedSMC", value: "0" },
            { label: "totalUnqualifiedSMC", value: "0" },
            { label: "totalQualifiedSMCRate", value: "0" },
            { label: "totalNEMC", value: "0" },
            { label: "totalQualifiedNEMC", value: "0" },
            { label: "totalUnqualifiedNEMC", value: "0" },
            { label: "totalQualifiedRateNEMC", value: "0" },
            { label: "totalQDMC", value: "0" },
            { label: "totalQualifiedQDMC", value: "0" },
            { label: "totalUnqualifiedQDMC", value: "0" },
            { label: "totalQualifiedRateQDMC", value: "0" },
            { label: "numberSatisfactionEvaluation", value: "0" },
            { label: "numberFiveStar", value: "0" },
            { label: "numberNotFiveStar", value: "0" },
            { label: "percentageFiveStar", value: "0" },
          ],
        },
        {
          label: "regionIOT",
          title: "regionIOT",
          name: "3",
          data: [
            { label: "amountMO", value: "0" },
            { label: "completeMO", value: "0" },
            { label: "unfinishedMO", value: "0" },
            { label: "completeRateMO", value: "0" },
            { label: "amountRO", value: "0" },
            { label: "completeRO", value: "0" },
            { label: "unfinishedRO", value: "0" },
            { label: "completeRateRO", value: "0" },
            { label: "amountTO", value: "0" },
            { label: "completeTO", value: "0" },
            { label: "unfinishedTO", value: "0" },
            { label: "completeRateTO", value: "0" },
            { label: "amountCFO", value: "0" },
            { label: "completeCFO", value: "0" },
            { label: "unfinishedCFO", value: "0" },
            { label: "completeRateCFO", value: "0" },
            { label: "hoursMO", value: "0" },
            { label: "hoursRO", value: "0" },
            { label: "hoursTO", value: "0" },
            { label: "hoursMRO", value: "0" },
            { label: "responseRO", value: "0" },
            { label: "repairRO", value: "0" },
            { label: "responseTO", value: "0" },
            { label: "rescueTO", value: "0" },
            { label: "trappedTimes", value: "0" },
            { label: "trappedRate", value: "0" },
            { label: "vehicleMilesTravelM", value: "0" },
            { label: "vehicleMilesTravelT", value: "0" },
            { label: "unitsSMCIn", value: "0" },
            { label: "qualifiedSMCIn", value: "0" },
            { label: "unqualifiedSMCIn", value: "0" },
            { label: "qualifiedRateSMCIn", value: "0" },
            { label: "totalUnitsNEMC", value: "0" },
            { label: "qualifiedNEMC", value: "0" },
            { label: "unqualifiedNEMC", value: "0" },
            { label: "qualifiedRateNEMC", value: "0" },
            { label: "unitsQDMC", value: "0" },
            { label: "qualifiedQDMC", value: "0" },
            { label: "unqualifiedQDMC", value: "0" },
            { label: "qualifiedRateQDMC", value: "0" },
            { label: "numberSatisfactionEvaluation", value: "0" },
            { label: "numberFiveStar", value: "0" },
            { label: "numberNotFiveStar", value: "0" },
            { label: "percentageFiveStar", value: "0" },
          ],
        },
        {
          label: "maintenanceStaffIOT",
          title: "maintenanceStaffIOT",
          name: "4",
          data: [
            { label: "amountMO", value: "0" },
            { label: "completeMO", value: "0" },
            { label: "unfinishedMO", value: "0" },
            { label: "completeRateMO", value: "0" },
            { label: "amountRO", value: "0" },
            { label: "completeRO", value: "0" },
            { label: "unfinishedRO", value: "0" },
            { label: "completeRateRO", value: "0" },
            { label: "amountTO", value: "0" },
            { label: "completeTO", value: "0" },
            { label: "unfinishedTO", value: "0" },
            { label: "completeRateTO", value: "0" },
            { label: "amountCFO", value: "0" },
            { label: "completeCFO", value: "0" },
            { label: "unfinishedCFO", value: "0" },
            { label: "completeRateCFO", value: "0" },
            { label: "hoursMO", value: "0" },
            { label: "hoursRO", value: "0" },
            { label: "hoursTO", value: "0" },
            { label: "hoursMRO", value: "0" },
            { label: "responseRO", value: "0" },
            { label: "repairRO", value: "0" },
            { label: "responseTO", value: "0" },
            { label: "rescueTO", value: "0" },
            { label: "trappedTimes", value: "0" },
            { label: "trappedRate", value: "0" },
            { label: "vehicleMilesTravelM", value: "0" },
            { label: "vehicleMilesTravelT", value: "0" },
            { label: "unitsSMC", value: "0" },
            { label: "qualifiedSMC", value: "0" },
            { label: "unqualifiedSMC", value: "0" },
            { label: "qualifiedRateSMC", value: "0" },
            { label: "unitsQDMC", value: "0" },
            { label: "qualifiedQDMC", value: "0" },
            { label: "unqualifiedQDMC", value: "0" },
            { label: "qualifiedRateQDMC", value: "0" },
            { label: "numberSatisfactionEvaluation", value: "0" },
            { label: "numberFiveStar", value: "0" },
            { label: "numberNotFiveStar", value: "0" },
            { label: "percentageFiveStar", value: "0" },
          ],
        },
      ],
    });

    const onSubmit = () => {};

    const init = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.searchColumns.map((item) => {
        if (item.label === "maintenancePerson") {
          item.data = data;
        }
      });
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#servicePlatformData {
  .form {
    margin: 10px 0 30px;
    border-left: 1px solid $border_color;
    border-top: 1px solid $border_color;
    text-align: center;
    .item {
      width: 25%;
      display: inline-block;
      label {
        background: $blue2_color;
      }
      label,
      span {
        display: block;
        min-height: 37px;
        line-height: 37px;
        border-right: 1px solid $border_color;
        border-bottom: 1px solid $border_color;
      }
    }
  }
}
</style>
